<template>
    <div>
        <el-breadcrumb separator-class="el-icon-arrow-right">
            <el-breadcrumb-item :to="{ path: '/welcome' }" v-if="$_isAdmin()">首页</el-breadcrumb-item>
            <el-breadcrumb-item :to="{ path: '/dashboard' }" v-else>首页</el-breadcrumb-item>
            
            <el-breadcrumb-item>我的线索</el-breadcrumb-item>
        </el-breadcrumb>

        <el-card class="box-card">
        <div class="text item">
            <div v-if="$_isMobile()">
                <el-row :gutter="20" type="flex" align="center">
                    <el-col :span="20">
                        <!--遗留bug 第二页 搜索不好使 ，新增一个查询方法，将当前页码设置为1 再调用getUserList-->
                        <el-input placeholder="请输入姓名或手机号查询" v-model="queryInfo.name" clearable @clear="getUserList">
                            <el-button slot="append" icon="el-icon-search" @click="searchBtn"></el-button>
                        </el-input>
                    </el-col>
                </el-row>
                <el-row :gutter="20" type="flex" align="center">
                    <!--:picker-options="pickerOptions"--->
                    <el-col :span="20">
                        <el-date-picker
                            v-model="creatTime"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            @change="selectCreatTime(creatTime)"
                            value-format="yyyy-MM-dd"
                            >
                        </el-date-picker>
                    </el-col>
                </el-row>
            </div>
            <div v-else>
                <el-row :gutter="20">
                    <el-col :span="6">
                        <!--遗留bug 第二页 搜索不好使 ，新增一个查询方法，将当前页码设置为1 再调用getUserList-->
                        <el-input placeholder="请输入姓名或手机号查询" v-model="queryInfo.name" clearable @clear="getUserList">
                            <el-button slot="append" icon="el-icon-search" @click="searchBtn"></el-button>
                        </el-input>
                    </el-col>
                    <!--:picker-options="pickerOptions"--->
                    <el-col :span="8">
                        <el-date-picker
                            v-model="creatTime"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            :picker-options="pickerOptions"
                            @change="selectCreatTime(creatTime)"
                            value-format="yyyy-MM-dd"
                            >
                        </el-date-picker>
                    </el-col>
                    <!--v-show="$_has('useradmin:export')"-->
                    <el-col :span="3">
                        <el-button type="primary" v-show="$_has('crmuser:export')" @click="handleExportXls()">导出</el-button>
                    </el-col>
                </el-row>
            </div>
            <el-table :data="userList" style="width: 100%" border stripe  @selection-change="handleSelectionChange"  ref="multipleTable">
                <!-- <el-table-column type="selection" label="全选" width="55"></el-table-column> -->
                <el-table-column type="index" label="序号"></el-table-column>
                <el-table-column prop="name" label="姓名" ></el-table-column>
                <el-table-column prop="phone" label="手机号" ></el-table-column>
                <el-table-column prop="appName" label="来源" ></el-table-column>
                <el-table-column prop="regionName" label="地区" ></el-table-column>
                <el-table-column prop="departmentName" label="科室" ></el-table-column>
                <el-table-column prop="gender" label="性别" ></el-table-column>
                <el-table-column prop="age" label="年龄" ></el-table-column>
                <el-table-column prop="followStatusName" label="跟进状态" ></el-table-column>
                <el-table-column prop="followRecordTxt" label="跟进备注" ></el-table-column>
                <!-- <el-table-column prop="createTime" label="创建时间" ></el-table-column> -->
                 <el-table-column prop="assignTime" label="分配时间" ></el-table-column>
                
                <el-table-column label="操作" width="100px" header-align="center">
                    <template v-slot="scope">
                        <!--跟进-->
                        <el-button type="primary" v-show="$_has('crmuser:follow')"  icon="el-icon-finished" size="mini" @click="getFollowList(scope.row.id)">跟进</el-button>
                        

                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                v-if="$_isMobile()"
                background
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.pageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.pageSize"
                layout="prev, pager, next"
                :total="total"
                pager-count="5">
            </el-pagination>
            <el-pagination
                v-else
                background
                prev-text="上一页"
                next-text="下一页"
                @size-change="handleSizeChange"
                @current-change="handleCurrentChange"
                :current-page="queryInfo.pageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.pageSize"
                layout="total, sizes, prev, pager, next"
                :total="total">
            </el-pagination>
        </div>
        </el-card>


        <!--添加用户的对话框 ；async是vue1 现在vue2改为v-model-->
        <el-dialog
            :title="this.addOReditDialogVisible == 0 ? '添加线索' : '修改线索'"
            :visible.sync="addDialogVisible"
            :width="$_isMobile()?'90%':'50%'"
            @close="addDialogClosed"
            :close-on-click-modal="false">
            <!--主题内容区-->
            <el-form :model="addForm" :rules="addFormRules" ref="addFormRef" label-width="80px">
                <el-form-item label="姓名" prop="name" >
                    <el-input v-model="addForm.name" placeholder="请输入姓名"></el-input>
                </el-form-item>
                <el-form-item label="手机号" prop="phone" >
                    <el-col :span="16">
                        <el-input v-model="addForm.phone" placeholder="请输入手机号" style="width: 100%;"></el-input>
                    </el-col>
                </el-form-item>

                <el-form-item label="来源" prop="app_name" >
                    <el-col :span="16">
                        <el-radio v-model="addForm.app_name" label="字节-抖音">字节-抖音</el-radio>
                        <el-radio v-model="addForm.app_name" label="快手">快手</el-radio>
                        <el-radio v-model="addForm.app_name" label="朋友圈">朋友圈</el-radio>
                    </el-col>
                </el-form-item>
                
                <el-form-item label="所在地" prop="regionCode">
                    <el-cascader ref="cascader" v-model="addForm.regionCode" :options="cityList" :show-all-levels="false" :props="props" clearable @change="changeCityValue()"></el-cascader>
                </el-form-item>

                <el-form-item label="科室" prop="departmentId">
                    <!-- <el-input v-model="addForm.natureBusiness"></el-input> -->
                    
                    <el-select v-if="this.addOReditDialogVisible == 0" v-model="addForm.departmentIdList" multiple placeholder="请选择科室" label="科室" clearable @change="changeDepartmentIdValue()">
                        
                        <el-option
                            v-for="item in departmentIdScope"
                            :key="item.id"
                            :label="item.deptName"
                            :value="item.id">
                        </el-option>
                    </el-select> 
                    <el-input v-else  v-model="addForm.departmentId"></el-input>
                </el-form-item>
                <el-form-item label="QQ" prop="qq" >
                    <el-input v-model="addForm.qq" placeholder="请输入QQ号"></el-input>
                </el-form-item>
                <el-form-item label="年龄" prop="age" >
                    <el-input v-model="addForm.age" placeholder="请输入年龄"></el-input>
                </el-form-item>
                <el-form-item label="性别" prop="gender" >
                    <el-radio v-model="addForm.gender" label="男">男</el-radio>
                    <el-radio v-model="addForm.gender" label="女">女</el-radio>
                </el-form-item>
                
                

                <el-form-item label="id" prop="id" v-show="false" hidden="true">
                    {{addForm.id}}
                </el-form-item>
            </el-form>
            <!--底部区-->
            <span slot="footer" class="dialog-footer">
                <el-button @click="addDialogVisible = false">取 消</el-button>
                <el-button type="primary" v-if="this.addOReditDialogVisible == 0" @click="addUser()">确 定</el-button>
                <el-button type="primary" v-else-if="this.addOReditDialogVisible == 1" @click="editUser()">确 定</el-button>
            </span>
        </el-dialog>

        <!-- :before-close="handleClose" -->
        <el-drawer
            title="跟进记录"
            :visible.sync="drawer"
            :direction="direction"
            :size="$_isMobile()?'90%':'50%'"
            @close="drawerClosed">
            <el-form :model="followForm" :rules="followFormRules" ref="followFormRef" label-width="80px">
                
                <el-form-item label="跟进状态" prop="followStatus">
                    <el-radio-group  v-model="followForm.followStatus" style="line-height: 32px">
                        <el-radio :label="item.value" :key="item.value" v-for="item in followStatusList" >{{item.title}}</el-radio>
                    </el-radio-group>
                </el-form-item>

                <el-form-item label="备注" prop="remarks" >
                    <el-input type="textarea" width="100px" v-model="followForm.remarks" placeholder="请输入跟进记录"></el-input>
                </el-form-item>
                <!-- v-show="false" hidden="true"-->
                <!-- <el-form-item label="uId" prop="uId">
                    <el-input placeholder="请输入内容" v-model="followForm.uId" :disabled="true"></el-input>
                </el-form-item> -->
                <el-form-item label="uId" prop="uId" v-show="false" hidden="true">
                    {{queryInfo.uId}}
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addFollow(queryInfo.uId)">提交跟进记录</el-button>
                </el-form-item>
            </el-form>
            

            <el-table :data="followList" style="width: 100%" border stripe  @selection-change="handleSelectionChange"  ref="multipleTable2">
                <el-table-column prop="createTime" label="跟进时间" ></el-table-column>
                <el-table-column prop="followStatusName" label="状态" ></el-table-column>
                <el-table-column prop="sname" label="执行人" ></el-table-column>
                <!-- <el-table-column prop="hospitalName" label="所属医院" ></el-table-column> -->
                <el-table-column prop="remarks" label="备注" ></el-table-column>
       
            </el-table>

            <el-pagination
                v-if="$_isMobile()"
                background
                @size-change="followhandleSizeChange"
                @current-change="followhandleCurrentChange"
                :current-page="queryInfo.followpageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.followpageSize"
                layout="prev, pager, next"
                :total="followtotal">
            </el-pagination>
            <el-pagination
                v-else
                background
                prev-text="上一页"
                next-text="下一页"
                @size-change="followhandleSizeChange"
                @current-change="followhandleCurrentChange"
                :current-page="queryInfo.followpageNo"
                :page-sizes="[10,20,50]"
                :page-size="queryInfo.followpageSize"
                layout="total, sizes, prev, pager, next"
                :total="followtotal">
            </el-pagination>
        </el-drawer>

    </div>
</template>

<script>
export default {
    data(){
       
        return{
            roles: [],
            //获取用户列表的参数对象
            queryInfo:{
                name:'',
                username:'',
                status:'',
                lastSelectRoleList:'',
                createTime:'',
                pageNo:0,//当前页数
                pageSize:10,//当前每页显示多少条

                followpageNo:0,//当前页数
                followpageSize:10,//当前每页显示多少条

                uId:'',
            },

            cityList:[],
            cityValue:[], // 值
            typeList:[],

            userList:[],
            total:0, //共多少条数据
            addDialogVisible:false,//控制对话框的显示与隐藏
            addForm:{
                id:'',
                name:'',
                phone:'',
                source:'',
                selectRoleList:[],
                lastSelectRoleList:'',
               

                beginTime:'',
                endTime:'',


                regionCode:'',
                regionCodeList:[],
                departmentId:'',
                departmentIdList:[],
            },//添加用户的表单数据

            followForm:{
                followStatus:'10',
                uId:'',
                remarks:'',
            },

            departmentIdScope:[],
            creatTime:[],
            rolesList:[],//添加弹出框
            rolesListSearch:[],//搜索条件
            addFormRules:{//验证规则
                
            },
            addFormRef:{},

            followFormRules:{//验证规则
                
            },
            followFormRef:{},

            pickerOptions: {
                shortcuts: [{
                    text: '最近一周',
                    onClick(picker) {
                    var end = new Date();
                    var start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                    //start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
                    //end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
                    picker.$emit('pick', [start,end]);
                    }
                }, {
                    text: '最近一个月',
                    onClick(picker) {

                    var end = new Date();
                    var start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                    //start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
                    //end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
                    picker.$emit('pick', [start,end]);
                    }
                }, {
                    text: '最近三个月',
                    onClick(picker) {
                    var end = new Date();
                    var start = new Date();
                    start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                    //start = start.getFullYear() + '-' + (start.getMonth() + 1) + '-' + start.getDate();
                    //end = end.getFullYear() + '-' + (end.getMonth() + 1) + '-' + end.getDate();
                    picker.$emit('pick', [start,end]);
                    }
                }]
            },


            addOReditDialogVisible:0, //0是add，1是edit

            multipleSelection: [], //全选按钮



            props:{
                value: 'code',
                label: 'name',
                children: 'districtCodeDtos',
                checkStrictly: true,
                
            },

            //跟进记录 抽屉
            drawer: false,
            direction: 'rtl',

            followList:[],
            followtotal:0,
            followStatusList:[],//跟进状态


        }
    },
    created(){
        this.getUserList()
        this.getRoleListSearch()
        this.getCityList()
        this.getDepartmentList()
    },
    methods:{
        async getUserList(){
            //console.log("????????");
           const {data:res} = await this.$http.get('crm/user/list',{params:this.queryInfo});
           console.log(res);
           if(res.code !== 200) return this.$message.error('获取用户列表失败')
           this.userList = res.result.records;
           this.total = res.result.total;
        },
        async getFollowList(uId){
           console.log("跟进记录列表");
           this.drawer = true;
           this.queryInfo.uId = uId;//放到查询条件里
           this.getFollowStatusList();
           const {data:res} = await this.$http.get('crm/followrecord/list',{params:this.queryInfo});
           console.log(res);
           if(res.code !== 200) return this.$message.error('获取跟进记录列表失败')
           this.followList = res.result.records;
           this.followtotal = res.result.total;
        },
        // handleClose(done) {
        //     this.$confirm('确认关闭？')
        //     .then(_ => {
        //         done();
        //     })
        //     .catch(_ => {});
        // },
        async getFollowStatusList(){
           
           const {data:res2} = await this.$http.get('sys/dict/getDictItems/followStatus');
           console.log("followStatus");
           console.log(res2);
           this.followStatusList = res2.result;
        },
        //监听pagesize改变事件
        handleSizeChange(newSize){
            console.log(newSize);
            this.queryInfo.pageSize = newSize;
            this.getUserList();
        },
        //监听页码值改变的事件
        handleCurrentChange(newPage){
            console.log(newPage);
            this.queryInfo.pageNo = newPage;
            this.getUserList();
        },
        //监听pagesize改变事件
        followhandleSizeChange(follownewSize){
            console.log(follownewSize);
            this.queryInfo.followpageSize = follownewSize;
            console.log("followhandleSizeChange-followForm.uId:"+this.followForm.uId);
            this.getFollowList(this.queryInfo.uId);
        },
        //监听页码值改变的事件
        followhandleCurrentChange(follownewPage){
            console.log(follownewPage);
            this.queryInfo.followpageNo = follownewPage;
            this.getFollowList(this.queryInfo.uId);
        },
        //搜索 状态下拉
        selectStatus(status){
            console.log(status+"~~~~~~~~~~~");
            this.queryInfo.status = status;
            this.searchBtn();
        },
        //搜索 角色下拉
        selectRoleList(lastselectRoleList){
            console.log(lastselectRoleList+"~~~~~~~~~~~");
            //this.queryInfo.lastselectRoleList = lastselectRoleList;
            this.searchBtn();
        },
        //搜索条件 创建时间段
        selectCreatTime(creatTime){
            if(creatTime==null) {
                this.creatTime = [];
                this.queryInfo.beginTime = '';
                this.queryInfo.endTime = '';
            }else{
                this.queryInfo.beginTime = creatTime[0];
                this.queryInfo.endTime = creatTime[1];
            }

            console.log(creatTime+"~~~~~~~~~~~");
            this.searchBtn();
        },

        searchBtn(){
            this.queryInfo.pageNo=1
            this.getUserList()
        },

        
        async getRoleListSearch(){
            const {data:res} = await this.$http.get('sys/role/queryall');

            console.log(res);
            if(res.code !== 200){
                return this.$message.error('获取角色失败');
            }
            //console.log(res.result);
            this.rolesListSearch = res.result
        },

        //显示添加用户的对话框
        async showAddDialog(){
            //const {data:res} = await this.$http.get('sys/user/queryUserRoleName?userid='+JSON.parse(window.sessionStorage.getItem('userInfo')).id);
            const {data:res} = await this.$http.get('sys/role/queryall');

            console.log(res);
            if(res.code !== 200){
                return this.$message.error('获取角色失败');
            }
            //console.log(res.result);
            this.rolesList = res.result
            this.addDialogVisible = true
        },

        //监听用户添加Dialog关闭事件
        addDialogClosed(){
            this.$refs.addFormRef.resetFields()
            this.addOReditDialogVisible=0
        },
        //监听用户添加Dialog关闭事件
        drawerClosed(){
            this.$refs.followFormRef.resetFields()
            this.drawer=false
            this.queryInfo.uId=''
            this.getUserList()
        },

        async getCityList(){
            //const {data:res} = await this.$http.get('sys/common/regionListAll');
            const {data:res} = await this.$http.get('sys/common/regionList');
           //console.log("||||||||||||||");
           //console.log(res);
           //this.cityList = res.result;
           this.cityList = this.getTreeData(res);
            //console.log("this.cityList");
           //console.log(this.cityList);
            
        },
        // 递归判断列表，把最后的children设为undefined
        getTreeData(data){
            for(var i=0;i<data.length;i++){
                if(!data[i].districtCodeDtos){
                // children若为空数组，则将children设为undefined
                console.log("付值undefined");
                data[i].districtCodeDtos=undefined;
                }else {
                // children若不为空数组，则继续 递归调用 本方法
                this.getTreeData(data[i].districtCodeDtos);
                }
            }
            //console.log(data);
            return data;
        },
        changeCityValue(){
            let nodevalue = this.$refs['cascader'].getCheckedNodes();
            console.log("<><><>><><><><><><><");
            console.log(nodevalue[0].value);
            this.addForm.regionCode = nodevalue[0].value;
            this.$refs.cascader.dropDownVisible = false; //监听值发生变化就关闭它
        },
        changeDepartmentIdValue(){
        //     //console.log(scopeBusiness+"~~~~~~~~~~~");
        //     // this.queryInfo.type = type;
        //     //  this.farmmachinery.addForm.regionCode = nodevalue[0].value;
             //this.addForm.departmentIdList = JSON.parse(this.addForm.departmentId);
             this.addForm.departmentId = this.addForm.departmentIdList.join(',');
             console.log("this.addForm.departmentIdList");
             console.log(this.addForm.departmentIdList);
             console.log("this.addForm.departmentId");
             console.log(this.addForm.departmentId);
             
        },
        async getDepartmentList(){
           const {data:res} = await this.$http.get('crm/department/getall');
           console.log("getDepartmentIdList");
           console.log(res);
           this.departmentIdScope = res.result;
        },

        addUser(){
             this.$refs.addFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求

                 this.addForm.lastSelectRoleList = this.addForm.selectRoleList.join(',');
                 console.log(this.addForm.lastSelectRoleList);
                 console.log(this.addForm);
                 const {data:res} = await this.$http.post('crm/user/add',this.addForm);
                 console.log("+++++++addUser+++++++");
                  console.log(res);

                //  if(res.code !== 201) {
                //      //少一个return？
                //      return this.$message.error('添加用户失败');
                //  }
                 if(res.code == 500) {
                     return this.$message.error(res.message);
                 }

                 this.$message.success('添加用户成功');
                 this.addDialogVisible = false;//关闭添加用户的窗口
                 this.getUserList();//从新获取用户列表数据
             })
        },

        addFollow(uId){
             this.$refs.followFormRef.validate(async valid=>{
                 console.log(valid);
                 if(!valid) return
                 //可以发起网络请求
                 console.log("直接取followForm的值"+this.followForm);
                 console.log("调用addFollow传过来的"+uId);
                 this.followForm.uId=uId;
                 console.log("uId负值给followForm之后的值"+this.followForm.uId);
                 console.log(this.followForm);
                 const {data:res} = await this.$http.post('crm/followrecord/add',this.followForm);
                 console.log("+++++++addFollow+++++++");
                  console.log(res);

                //  if(res.code !== 201) {
                //      //少一个return？
                //      return this.$message.error('添加用户失败');
                //  }
                 if(res.code == 500) {
                     return this.$message.error(res.message);
                 }

                 this.$message.success('添加跟进记录成功');
                 this.getFollowList(uId);//从新获取用户列表数据
                 this.followForm.remarks='';
                 this.followForm.followStatus=0

             })
        },

        //显示编辑用户的对话框
        async showEditDialog(){
            
            this.addOReditDialogVisible=1;
            const {data:res} = await this.$http.get('crm/crmfollowrecord/list');
            console.log(res);
            if(res.code !== 200){
                return this.$message.error('查询跟进记录失败');
            }

            const {data:res2} = await this.$http.get('sys/role/queryall');
            console.log(res2);
            if(res2.code !== 200){
                return this.$message.error('获取角色失败');
            }


            //问题的本质是因为你编辑时，第一次打开dialog的时候给表单绑定的form赋值了，这时候这个form的初始值就变成了你所赋值的值，所以resetFields的时候，会将form对应的每个值重置到初始值，这时候的初始值就是你编辑时赋值的那个值, 而不是在data里声明的初始值，
            //解决方式是，等dialog已经初始化之后再给form赋值，也就是
            this.addDialogVisible = true
            this.$nextTick(() => {
                this.addForm = res.result
                console.log("showEditDialog");
                console.log(res);
                this.rolesList = res2.result
            })


        },

        

       
        toggleSelection(rows) {
            if (rows) {
            rows.forEach(row => {
                this.$refs.multipleTable.toggleRowSelection(row);
            });
            } else {
            this.$refs.multipleTable.clearSelection();
            }
        },
        handleSelectionChange(val) {
            this.multipleSelection = val;
        },

        async handleExportXls(){
            
            const {data:res} = await this.$http.get('crm/user/exportXlsByEnterprise',{params:this.queryInfo,responseType: 'blob'});
            console.log("导出excel");
            console.log(res);
            // 'Cache-Control': 'no-cache',
            // 'Content-Type': 'application/x-www-form-urlencoded'
            // application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8
            let blob = new Blob([res], {type: "application/x-www-form-urlencoded"}); // 为blob设置文件类型，这里以.xlsx为例
            //console.log(blob);
            //注意一定要和后端协调好返回的数据格式，不然会出现中文乱码问题
             //fileName = res.headers['content-disposition'].split('filename=').pop();
             //fileName = res.headers['content-disposition'];
           // console.log(fileName);
            // if ('msSaveOrOpenBlob' in navigator) {
            //     window.navigator.msSaveOrOpenBlob(blob, fileName);
            // } else {
            //     const elink = document.createElement('a');
            //     elink.download = fileName;
            //     elink.style.display = 'none';
            //     elink.href = URL.createObjectURL(blob);
            //     document.body.appendChild(elink);
            //     elink.click();
            //     URL.revokeObjectURL(elink.href);
            //     document.body.removeChild(elink);
            // }

            let url = window.URL.createObjectURL(blob); // 创建一个临时的url指向blob对象
             console.log("url");
             //console.log(this.$_has);
             console.log(this.$_getNowFormatDate());
            let a = document.createElement("a");

            a.download = "线索导出数据-"+this.$_getNowFormatDate()+".xlsx"
            a.href = url;
            a.click();
            // 释放这个临时的对象url
            window.URL.revokeObjectURL(url); 
            //document.body.removeChild(a);

        },

    }
}
</script>

<style lang="less" scoped>
/deep/ .el-table tbody tr:hover>td { background-color: rgb(217, 236, 255) !important;}
.el-row {
    margin-bottom: 10px;
    &:last-child {
      margin-bottom: 0;
    }
}
/*1.显示滚动条：当内容超出容器的时候，可以拖动：*/
/deep/ .el-drawer__body {
    overflow: auto;
    /* overflow-x: auto; */
}

/*2.隐藏滚动条，太丑了*/
/deep/ .el-drawer__container ::-webkit-scrollbar{
    display: none;
}

/deep/ .el-date-editor--daterange.el-input, .el-date-editor--daterange.el-input__inner, .el-date-editor--timerange.el-input, .el-date-editor--timerange.el-input__inner{
    width: 300px;
}

/deep/ .el-drawer{
    background-color: #fff;
}

/deep/ .el-textarea__inner{
    width: 90%;
}
</style>
